@import url("https://use.typekit.net/rug8shp.css");

$red: #fc0010;
$black: rgb(25, 25, 25);
$borderWidth: 4px;
$paddingY: 0.35em;
$sigFont: "proxima nova", "proxima-nova", "Avenir Next", "Helvetica Neue", Helvetica, sans-serif;


.ordering-menu {
  background: #f7f7fc;
  /* background: none; */
  // color: white;
}

// }
.sushidon {
  /* @import url('https://fonts.googleapis.com/css?family=Fredoka+One'); */
  /* https://res.cloudinary.com/dgps1radv/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
  .footer:after  {
    
      content: "Jayvee60@hotmail.com"
    
  }
  img.nav-bar-logo {
    display: block;
    /* padding-left: 0.8em; */
    width: 200px;
    height: auto !important;
  }
  .cart.minimized {
    background-color:#09386c;
    background-image: radial-gradient(circle at 100% 150%, #09386c 24%, #236c9b 25%, #236c9b 28%, #09386c 29%, #09386c 36%, #236c9b 36%, #236c9b 40%, transparent 40%, transparent), radial-gradient(circle at 0 150%, #09386c 24%, #236c9b 25%, #236c9b 28%, #09386c 29%, #09386c 36%, #236c9b 36%, #236c9b 40%, transparent 40%, transparent), radial-gradient(circle at 50% 100%, #236c9b 10%, #09386c 11%, #09386c 23%, #236c9b 24%, #236c9b 30%, #09386c 31%, #09386c 43%, #236c9b 44%, #236c9b 50%, #09386c 51%, #09386c 63%, #236c9b 64%, #236c9b 71%, transparent 71%, transparent), radial-gradient(circle at 100% 50%, #236c9b 5%, #09386c 6%, #09386c 15%, #236c9b 16%, #236c9b 20%, #09386c 21%, #09386c 30%, #236c9b 31%, #236c9b 35%, #09386c 36%, #09386c 45%, #236c9b 46%, #236c9b 49%, transparent 50%, transparent), radial-gradient(circle at 0 50%, #236c9b 5%, #09386c 6%, #09386c 15%, #236c9b 16%, #236c9b 20%, #09386c 21%, #09386c 30%, #236c9b 31%, #236c9b 35%, #09386c 36%, #09386c 45%, #236c9b 46%, #236c9b 49%, transparent 50%, transparent);
    background-size: 100px 50px;
    /* padding-top: 0; */
    border-bottom: 2px solid white;
    padding-bottom: 0.2em;

  }

  a.checkout-button.button {
    background: #fc0006;

    font-weight: 600;
    box-shadow: none;
    border: 2px solid #eee;

  }

  /* .category__header {
    font-family: cursive;

  } */
  .pages-nav {
    text-transform: uppercase;
    font-weight: 700;
    font-family: $sigFont;
    background: #111;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    text-decoration: none;
    color: white;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid #111;
    &:hover, &.active {
      border-bottom: $borderWidth solid white;
      color: white;
    }
  }
  .category-name .text {
    font-family: $sigFont;
    font-weight: 700;
    font-size: 1.5em;
    background: none;
    color: black;

  }
  .category-name .text::before {
    content: "• ";
    /* font-size: 1.2em; */
    color:#fc0006;
  }
  .category-name .text::after {
    content: " •";
    color:#fc0006;
  }

  .landing-page {
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1540871172/o-1_jcyhpy.jpg);
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .landing-page .text {
    /* color: black; */
    font-family: $sigFont;
    font-size: 1.3em;
    text-align: center;
    font-weight: 700;
    background: rgba(255,255,255,0.75);
    padding: 0.5em 0.8em;
  }
}
